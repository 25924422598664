import React from 'react';
import classnames from 'classnames';
import Layout from '../components/layout';
import styles from './shantala-massage.module.scss';

const Index = () => (
  <Layout>
    <div className={styles.shantalaPage}>
      <div className={classnames(['columns is-gapless', styles.intro])}>
        <div className={classnames(['column'])}>
          <div className={styles.introText}>
            <h1>
              Baby massage is a lovely way to enjoy time with your baby and it
              can also help you bond with them.
            </h1>
            <p>
              Before babies are able to understand language, we often
              communicate and comfort them through touch.
              <br />
              If a baby cries, for example, parents will hold, cuddle or stroke
              them.
            </p>
            <p>Baby massage is part of this natural impulse.</p>
          </div>
        </div>
        <div className={classnames(['column', styles.introPic])}></div>
      </div>
      <div className="paper">
        <h4>What are the benefits of baby massage?</h4>
        <div className={classnames(['columns', styles.quoteWrapper])}>
          <p className={classnames(['column', styles.explanation])}>
            Baby massage was introduced about 30 years ago in neonatal wards to
            support the development of premature babies in intensive care units.
            A study in 2004 found that babies in intensive care units who were
            massaged spent less time in hospital, had slightly better scores on
            developmental tests and slightly fewer postnatal complications. 
          </p>
          <div className={classnames(['column'])}>
            <blockquote>
              Today, there is widespread belief that baby massage can increase a
              mum’s awareness of her baby’s needs and support their early bond,
              as well as improve her sense of well-being if she is suffering
              with postnatal depression or other mental health issues. The
              evidence is inconclusive on these points but parents do say they
              find baby massage a lovely way of bonding with their baby.
            </blockquote>
            <p className={styles.source}>nct.org.uk</p>
          </div>
        </div>
      </div>

      <div className={styles.benefits}>
        <h4>Many talk about the following benefits:</h4>
        <div className={classnames(['columns', styles.benefitsWrapper])}>
          <div className={classnames(['column', styles.talksLeft])}></div>
          <div className={classnames(['column', styles.talks])}>
            <ul>
              <li>
                Becoming more confident in handling their child and better at
                recognising their needs.
              </li>
              <li>Improved positive interaction with their baby.</li>
              <li>
                A great way for partners, family members and carers to bond with
                baby.
              </li>
              <li>Improved sleep for their baby</li>
              <li>improves digestion</li>
              <li>makes it easier for the child to calm down</li>
              <li>
                raises awareness of one's body and its borders; in this way it
                increases the sense of security
              </li>
              <li>
                increases the body's immunity, stimulates the endocrine system{' '}
              </li>
            </ul>
          </div>
          <div className={classnames(['column', styles.talksRight])}></div>
        </div>
      </div>
      <div className={styles.summary}>
        <h3>You are very welcome to join the Shantala baby massage group!</h3>
        <p className={styles.contact}>
          To enjoy, call me at 07508609703 or send me an email
          iwona.libich@gmail.com
        </p>
        <p className={styles.greetings}>See you soon!</p>
      </div>
    </div>
  </Layout>
);

export default Index;
